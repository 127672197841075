<template>
  <v-app>
    <!--Header-->
    <component :is="Header as string" />

    <!--Content-->
    <v-main>
      <v-container class="j-container j-container--fw ma-0 pa-0 px-4 px-sm-15 mx-auto">
        <v-sheet
          :color="scssVariables.jBgMain"
          class="j-authentication-layout d-flex flex-column flex-lg-row"
        >
          <div class="j-authentication-layout__form d-flex justify-center align-lg-center align-start flex-grow-1">
            <slot />
          </div>
          <div class="mb-4 mb-lg-0">
            <v-img
              v-if="isMobile"
              :lazy-src="
                useImgSrc($route.meta.isRegistration
                  ? images[`loginImageRegistrationMobile-${$i18n.locale}`]
                  : images['loginImageMobile'])
              "
              :src="
                useImgSrc($route.meta.isRegistration
                  ? images[`loginImageRegistrationMobile-${$i18n.locale}`]
                  : images['loginImageMobile'])
              "
              alt="registration"
            />
            <!-- TODO: Find solution with v-img -->
            <img
              v-else
              class="j-authentication-layout__img"
              loading="lazy"
              :src="
                useImgSrc($route.meta.isRegistration
                  ? images[`loginImageRegistration-${$i18n.locale}`]
                  : images['loginImage'], 'webp')
              "
              alt="registration"
            >
          </div>
        </v-sheet>
      </v-container>
      <j-mobile-menu v-if="isMobile" />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
const scssVariables = useScssVariables();
const images = useAssetsImages();
const isMobile = inject<Ref<boolean>>('isMobile');
const Header = computed(() => {
  return isMobile?.value
    ? resolveComponent('JHeaderMobile')
    : resolveComponent('JHeaderDesktop');
});
</script>

<style lang="scss">
//TODO: Fix DRY principle violation in current and default layout
.v-app {
  min-height: 100vh;
}

.v-main {
  background-color: $j-bg-main;
}

.j-authentication-layout {
  max-height: 100%;
  gap: 32px;

  @media (min-width: 1280px) {
    height: calc(100vh - 154px);
    gap: 60px;
  }
}

.j-authentication-layout__form {
  width: -moz-available;
}

// TODO: Find solution with v-img
.j-authentication-layout__img {
  max-width: 100%;
  max-height: 100%;
}
</style>
